.mainSection {
  background: linear-gradient(
      to right,
      rgba(1, 44, 94, 0.884),
      rgba(255, 255, 255, 0)
    ),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FrontPageImage.f14d6fec703863a5c3e1.png")
      no-repeat center center;
  background-size: cover;
}
.img-front {
  width: fit-content;
}
.img-download {
  width: fit-content;
}

@media (max-width: 950px) {
  .img-front {
    width: 100%;
  }
  .img-download {
    width: 100%;
  }
}
.sectionThree {
  background: linear-gradient(
      to right,
      rgba(1, 44, 94, 0.884),
      rgba(255, 255, 255, 0)
    ),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/MiddleImage.f518dcec264dcb8487e0.png")
      no-repeat center center;
  background-size: cover;
}
.callSection {
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(1, 44, 94)),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/SideRight.6dc8b3be71b175d7ce49.png")
      no-repeat center center;
  background-size: cover;
}

.whatsappSection {
  background: url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/SideLeft.e72cf52b93fb509dd9df.png")
    no-repeat center center;
  background-size: cover;
}

.aforeSearchSection {
  /*background: url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/MiddleImage.f518dcec264dcb8487e0.png") no-repeat center center;*/
  background-image: "url(/assets/images/MiddleImage.png)";
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-position: right;
}

/* .principalDownloadSection {
  background:
 /* url("/src/assets/images/aforeApp.png") no-repeat center center;  
   url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/aforeApp.2c0b147b9decca8f0b37.png") no-repeat center center; 

  background-size: cover;
  /*background-position: right;
} */

.item-wrapper {
  width: 50%;
  justify-content: flex-end;
  display: flex;
}

.rec-carousel-wrapper {
  position: relative;
}

@font-face {
  font-family: "SF UI";
  src: local("SF UI"),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/sf-ui-display-medium-58646be638f96.06adfd256f40f8baa0a0.woff")
      format("woff");
}
@font-face {
  font-family: "SF UI Light";
  src: local("SF UI Light"),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/sf-ui-display-light-58646b33e0551.6f4c2292920ad02a3d61.woff")
      format("woff");
}

@media (max-width: 800px) {
  .mainSection {
    background: linear-gradient(
        44.45deg,
        #002855 41.43%,
        rgba(0, 40, 85, 0) 86.2%
      ),
      url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/homeMobile.0165b17407d6d35ab484.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  .sectionThree {
    background: linear-gradient(0deg, #002855 21.35%, rgba(0, 40, 85, 0) 92.19%),
      url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/images/Section2Mob.5130ded03f30bac01ec0.png");
  }
  .whatsappSection {
    background-size: 100%;
  }
  .img-front {
    width: 100%;
  }
  .img-download {
    width: 100%;
  }
}

.mainSectionPerfilador {
  display: flex;
  align-items: center;

  background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
    url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FrontPageImage.f14d6fec703863a5c3e1.png");
  background-repeat: no-repeat;
  background-position: top -300px right 0px;
  background-size: 1950px 1250px;
}

@media (max-width: 900px) {
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.75), rgba(0, 40, 85, 0.75)),
      url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FrontPageImage.f14d6fec703863a5c3e1.png")
        center center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .loading {
    margin: 0px !important;
  }
  .img-front {
    width: 100%;
  }
  .img-download {
    width: 100%;
  }
}

@media (max-width: 700px) {
  .mainSectionPerfilador {
    background: linear-gradient(rgba(0, 40, 85, 0.4), rgba(0, 40, 85, 0.4)),
      url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/FrontPageImage.f14d6fec703863a5c3e1.png");
    background-repeat: no-repeat;
    /*background-position: top -2px right -272px;
    background-size: 1045px 753px;*/
    background-position: center center;
    background-size: cover;
  }
  .img-front {
    width: fit-content;
  }
  .img-download {
    width: fit-content;
  }
}
.ContainerRadioInput {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.radio-item {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}

.radio-item input[type="checkbox"] {
  display: none;
}

.radio-item label {
  color: black;
  font-weight: normal;
  display: flex;
}

.radio-item label > b {
  margin-top: 5px;
}

.img-mobileGoogle {
  height: 60px !important;
  width: 110% !important;
  position: relative !important;
  top: -20px !important;
}

.img-mobileAppStore {
  height: 62px !important ;
  width: 113% !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
  position: relative !important;
  top: -10px !important;
}

@media (max-width: 1004px) {
  .img-mobileGoogle {
    height: 54px !important;
    width: 154% !important;
    position: relative !important;
    top: -87px !important;
  }

  .img-mobileAppStore {
    height: 55px !important;
    width: 155% !important;
    margin-left: 40px !important;
    position: relative !important;
    top: -79px !important;
  }
}

@media (max-width: 800px) {
  .img-mobileGoogle {
    height: 54px !important;
    width: 55% !important;
    margin-top: 60px !important;
  }

  .img-mobileAppStore {
    height: 55px !important;
    width: 55% !important;
    margin-left: 10px !important;
    margin-top: 60px !important;
  }
}
@media (max-width: 420px) {
  .img-mobileGoogle {
    height: 54px !important;
    width: 90% !important;
  }

  .img-mobileAppStore {
    height: 55px !important;
    width: 95% !important;
    margin-left: 3px !important;
  }
}
@media (max-width: 375px) {
  .img-mobileGoogle {
    height: 54px !important ;
    width: 100% !important;
  }

  .img-mobileAppStore {
    height: 55px !important;
    width: 101% !important;
    margin-left: 1px !important;
  }
}
.radio-item label:before {
  content: " ";
  display: inline-block;
  position: relative;
  top: 5px;
  margin: 0 0px 0 0;
  width: 20px;
  height: 20px;
  border-radius: 11px;
  border: 1px solid black;
  background-color: transparent;
}

.radio-item input[type="checkbox"]:checked + label:after {
  border-radius: 11px;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;

  content: " ";
  display: block;
  border: 0px;
  background: #00efe8;
}

.radio-liq {
  align-items: center;
  align-content: center;
  display: flex;
}

.radio-liq input[type="radio"] {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  accent-color: #0061a0;
}
.radio-liq label {
  transform: translateY(2px);
}

.selectedStep.accomplished {
  background-color: #00efe8 !important;
  border: 1px solid #00efe8 !important;
}

.step-progress-bar {
  height: 30px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.circle-full {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #616267;
}
.circle-empty {
  width: 20px;
  height: 20px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: solid 1px #616267;
  background: #ffffff;
}
.line {
  width: 27px;
  border-top: solid 2px #616267;
  transform: translateY(9px);
}

.line-2 {
  width: 35px;
  border-top: solid 2px #616267;
  transform: translateY(15px);
}

.hide {
  display: none;
}

.MuiInput-root {
  width: 100% !important;
}
.MuiSelect-select.MuiSelect-select {
  transform: translate(10px, 0px);

  min-width: 100% !important;
}
.MuiPopover-root .MuiMenu-paper {
  max-height: 200px;

  /*top: 522px !important;
  min-width: 100px !important;
  left: 602px !important;*/
}

@media screen and (max-width: 600px) {
  .MuiSelect-select.MuiSelect-select {
    transform: translate(13px, 0px);
    padding-right: 11px !important;
  }
}
@media screen and (max-width: 768px) {
  .rec .rec-slider-container {
    margin: 0px !important;
  }
  .afore-value-container {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .aforeSearchSection {
    background: url("https://blog-principal-mx.pantheonsite.io/rocket/afores/static/media/womenMobile.385ce32e7e5625da0c65.png")
      no-repeat center center;
    /* url("/src/assets/images/womenMobile.png") no-repeat center center;  */

    background-size: cover;
    background-position: center;
    background-position-y: top;
  }
  .img-mobile {
    width: 98% !important;
  }
}

.rounded-image {
  border-radius: 16px !important;
}
