body {
  margin: 0px !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow-x: hidden !important;
}
@media (max-width: 320px) {
  body {
    margin: 0px !important;

    overflow-x: hidden !important;
  }
}

@media (max-width: 425px) {
  body {
    margin: 0px !important;

    overflow-x: hidden !important;
    background: blue;
  }
}
@media (max-width: 700px) {
  body {
    margin: 0px !important;

    background: blue;
    overflow-x: hidden !important;
  }
}
.sf-ui {
  font-family: "SF UI" !important;
}
.terms-and-conditions {
  font-size: 18px !important;
  text-decoration: none !important;
  border-bottom: none !important;
  color: #0076cf !important;
}
.terms-and-conditions-checkbox {
  font-size: 18px !important;
}
.leyenderror {
  font-size: 12px !important;
  color: #c00000 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Gradiante-fourSectionLp {
  background: linear-gradient(71.21deg, #004c97 29.17%, #002855 100%);
}

.MuiTooltip-tooltip {
  background: #fff !important;
  color: #0076cf !important;
  width: 120px !important;
  height: 45px !important;
  display: flex;
  border: none !important;
  border-radius: 30px !important;
  justify-content: center;
  align-items: center;
  font-family: "eliotpro";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  margin-bottom: 20px !important;
}
.MuiTooltip-arrow {
  color: #fff !important;
}
.MuiSlider-markLabel {
  color: #fff !important;
  top: 40px !important;
  font-size: 18px !important;
  font-family: "eliotpro";
  font-weight: 700 !important;
}
.MuiSlider-root {
  height: 15px !important;
  padding: 10px 0 !important;
}
.MuiSlider-thumb::after {
  width: 45px !important;
  height: 45px !important;
}
.MuiFormControl-root {
  flex-direction: row !important;
}

/* .MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip {


  transform-origin: center top !important;
  margin-top: -77px !important;


}

.css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
  left: 50px !important;
  top: 58px !important;
  transform: rotate(180deg) !important;
}   */

@media screen and (max-width: 800px) {
  .MuiTooltip-tooltip {
    font-family: "eliotprobold" !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    width: 96px !important;
    height: 36px !important;
    line-height: 24px !important;
    margin-right: 15px !important;
    margin-left: 5px !important;
  }
  .terms-and-conditions {
    font-size: 16px !important;
  }
  .app-btn-container {
    margin: 0 10px;
  }

  /* .MuiTooltip-popper[data-popper-placement*="bottom"] .css-1k51tf5-MuiTooltip-tooltip {
     transform-origin: center top !important;
    margin-top: -80px !important;
  
  
  }
  
  .css-1d3ml05-MuiPopper-root-MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-arrow {
    left: 40px !important;
    top: 50px !important;
    transform: rotate(180deg) !important;
  }   */
}

/**reescritura css para drupal*/
input[type="text"],
input[type="date"],
input[type="search"],
input[type="email"],
input[type="password"],
input[type="number"] {
  border: none;
  padding: 0px;
}
img {
  width: auto;
}

#f-bloq {
  display: none !important;
}

header {
  display: none !important;
}

footer {
  display: none !important;
}

.strong {
  font-size: 16px !important;
  font-weight: 700 !important;
}

.spanr {
  font-size: 20px !important;
  margin: 0px !important;
  position: relative !important;
  right: 9px;
  bottom: 20px;
}

.spanr2 {
  font-size: 16px !important;
  margin: 0px !important;
  position: relative !important;
  right: 1px !important;
  bottom: 16px !important;
}

.spanr3 {
  font-size: 15px !important;
  margin: 0px !important;
  position: relative !important;
  right: -1px !important;
  bottom: 10px !important;
}
.spanr4 {
  font-size: 15px !important;
  margin: 0px !important;
  position: relative !important;
  right: 10px !important;
  bottom: 20px !important;
}
.principal-name {
  min-width: 50px;
}
input[type="checkbox"],
input[type="radio"] {
  padding: 0px !important;
}

.StepsContainer {
  display: flex;
  align-items: center;
  padding: 0px 5% 0px 5%;
}

.CardsStepsOne {
  margin: 2% 4%;
}
.Qr {
  width: 100%;
}

.Store1 {
  margin-left: 25%;
  margin-bottom: 100px;
}
.ImagenAppGoogle {
  width: 50%;
}

.Store2 {
  margin-left: 10%;
  margin-bottom: 100px;
}
.GoogleAppIso {
  width: 75%;
}

@media (max-width: 720px) {
  .StepsContainer {
    margin-bottom: 15px;
  }
}

@media (max-width: 320px) {
  .StepsContainer {
    margin-bottom: 25px;
  }

  .Store1 {
    margin-left: 26%;
    margin-bottom: 100px;
  }
  .ImagenAppGoogle {
    width: 49%;
  }
  .Store2 {
    margin-left: 15%;
    margin-bottom: 100px;
  }
  .GoogleAppIso {
    width: 73%;
  }
}

.strongp {
  font-size: 18px !important;
  font-weight: 700 !important;
}

.strongpin {
  font-size: 18px !important;
  font-weight: 700 !important;
}

/**reescritura css para drupal*/

.region.region-primary-menu {
  display: none !important;
}

.afore11{
  width: 50%;
}
